import { copyToClipboard } from './dom';

export const handleCopyToClipboard = (textAreaId, setState, value = 'Copied!') => {
  copyToClipboard(textAreaId);

  setState(prevState => {
    setTimeout(() => {
      setState(prevState);
    }, 3000);

    return value;
  });
};

/* update field on a certain data for table without making another request */
export const updateDataTable = (dataTable, values = {}, selectedId) => {
  const arr = [];

  if (!selectedId || !dataTable || !selectedId) return arr;

  dataTable.forEach(item => {
    const temp = { ...item };

    if (temp.id === selectedId) {
      if (values.expiredAt) temp.expiredAt = values.expiredAt;
      if (values.status) temp.status = values.status;
    }

    arr.push(temp);
  });

  return arr;
};
