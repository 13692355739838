import { isPaidPlan } from '../pages/Settings/configs/plan-config';

let redux_username = null;
let plan_type = null;
let is_self_domain = null;

export const getUsername = uname => {
  redux_username = uname;
};

export const getPlanType = (type, isSelfDomain) => {
  plan_type = type;
  is_self_domain = isSelfDomain;
};

const checkPlanType = (uname, type, isSelfDomain) => {
  if (isPaidPlan(type ?? plan_type) && (isSelfDomain ?? is_self_domain) === true) {
    return `https://${uname}`;
  }
  return `https://${uname}.${process.env.REACT_APP_PUBLIC_URL}`;
};

export const baseURL = (username, planType, isSelfDomain) =>
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_PUBLIC_URL
    : checkPlanType(username, planType, isSelfDomain);

export const rootUrl = link => {
  return process.env.NODE_ENV === 'development' ? `${baseURL(redux_username)}/${link}` : `${baseURL(redux_username)}`;
};

export const paymeUrl = link => {
  return process.env.NODE_ENV === 'development'
    ? `${baseURL(redux_username)}/${link}/payme`
    : `${baseURL(redux_username)}/payme`;
};

export const catalogUrl = link => {
  return process.env.NODE_ENV === 'development'
    ? `${baseURL(redux_username)}/${link}/catalog`
    : `${baseURL(redux_username)}/catalog`;
};

export const bundlingCatalogUrl = link => {
  return process.env.NODE_ENV === 'development'
    ? `${baseURL(redux_username)}/${link}/bundling`
    : `${baseURL(redux_username)}/bundling`;
};

export const productCatalogUrl = link => {
  return process.env.NODE_ENV === 'development'
    ? `${baseURL(redux_username)}/${link}/product-catalog`
    : `${baseURL(redux_username)}/product-catalog`;
};

export const paymentLinkCatalogUrl = link => {
  return process.env.NODE_ENV === 'development'
    ? `${baseURL(redux_username)}/${link}/plink`
    : `${baseURL(redux_username)}/plink`;
};

export const webinarCatalogUrl = link => {
  return process.env.NODE_ENV === 'development'
    ? `${baseURL(redux_username)}/${link}/webinar`
    : `${baseURL(redux_username)}/webinar`;
};

export const podcastCatalogUrl = link => {
  return process.env.NODE_ENV === 'development'
    ? `${baseURL(redux_username)}/${link}/podcast`
    : `${baseURL(redux_username)}/podcast`;
};

export const ebookCatalogUrl = link => {
  return process.env.NODE_ENV === 'development'
    ? `${baseURL(redux_username)}/${link}/ebook`
    : `${baseURL(redux_username)}/ebook`;
};

export const audiobookCatalogUrl = link => {
  return process.env.NODE_ENV === 'development'
    ? `${baseURL(redux_username)}/${link}/audiobook`
    : `${baseURL(redux_username)}/audiobook`;
};

export const eventCatalogUrl = link => {
  return process.env.NODE_ENV === 'development'
    ? `${baseURL(redux_username)}/${link}/event`
    : `${baseURL(redux_username)}/event`;
};

export const coachingCatalogUrl = link => {
  return process.env.NODE_ENV === 'development'
    ? `${baseURL(redux_username)}/${link}/coaching`
    : `${baseURL(redux_username)}/coaching`;
};

export const onlineCourseCatalogUrl = link => {
  return process.env.NODE_ENV === 'development'
    ? `${baseURL(redux_username)}/${link}/course`
    : `${baseURL(redux_username)}/course`;
};

export const cohortCourseCatalogUrl = link => {
  return process.env.NODE_ENV === 'development'
    ? `${baseURL(redux_username)}/${link}/bootcamp`
    : `${baseURL(redux_username)}/bootcamp`;
};

export const fundraisingCatalogUrl = link => {
  return process.env.NODE_ENV === 'development'
    ? `${baseURL(redux_username)}/${link}/galang-dana`
    : `${baseURL(redux_username)}/galang-dana`;
};

export const fundraisingCatalogUrlV2 = link => {
  return process.env.NODE_ENV === 'development'
    ? `${baseURL(redux_username)}/${link}/galang-dana/v2`
    : `${baseURL(redux_username)}/galang-dana/v2`;
};

export const fundraisingGenericUrl = link => {
  return process.env.NODE_ENV === 'development'
    ? `${baseURL(redux_username)}/${link}/donate-now`
    : `${baseURL(redux_username)}/donate-now`;
};

export const zakatCalculatorURL = link => {
  return process.env.NODE_ENV === 'development'
    ? `${baseURL(redux_username)}/${link}/zakat-calculator`
    : `${baseURL(redux_username)}/zakat-calculator`;
};

export const portalCustomerLoginUrl = link => {
  return process.env.NODE_ENV === 'development'
    ? `${baseURL(redux_username)}/${link}/portal`
    : `${baseURL(redux_username)}/portal`;
};

export const supportUrl = (link, isSelfDomain) => {
  if (process.env.NODE_ENV === 'development') {
    return `${baseURL(redux_username)}/${link}/support`;
  }
  if (isSelfDomain) {
    return `${baseURL(redux_username)}/support`;
  }
  return `${process.env.REACT_APP_SUPPORT_URL}/${link}`;
};

export const PDFLink = link => {
  return `${baseURL(redux_username)}/invoice/${link}`;
};

export const physicalProductUrl = (link, paymeLink = '', planType, isSelfDomain) => {
  if (paymeLink) {
    return `${baseURL(paymeLink, planType, isSelfDomain)}/checkout/${link}`;
  }
  return `${baseURL(redux_username)}/checkout/${link}`;
};

export const paymentLinkUrl = (link, paymeLink = '', planType, isSelfDomain) => {
  if (paymeLink) {
    return `${baseURL(paymeLink, planType, isSelfDomain)}/pl/${link}`;
  }
  return `${baseURL(redux_username)}/pl/${link}`;
};

export const paymentLinkUrlTransactions = (link, paymeLink = '') => {
  if (paymeLink) {
    return `${baseURL(paymeLink)}/plt/${link}`;
  }
  return `${baseURL(redux_username)}/plt/${link}`;
};

export const onlineCourseUrl = (link, paymeLink = '') => {
  if (paymeLink) {
    return `${baseURL(paymeLink)}/course/${link}`;
  }
  return `${baseURL(redux_username)}/course/${link}`;
};

export const cohortAttendanceUrl = (paymentLinkSlug, sessionId, paymeLink = '') => {
  if (paymeLink) {
    return process.env.NODE_ENV === 'development'
      ? `${baseURL(paymeLink)}/attendance/${paymeLink}/${paymentLinkSlug}/${sessionId}`
      : `${baseURL(paymeLink)}/attendance/${paymentLinkSlug}/${sessionId}`;
  }

  return process.env.NODE_ENV === 'development'
    ? `${baseURL(redux_username)}/attendance/${redux_username}/${paymentLinkSlug}/${sessionId}`
    : `${baseURL(redux_username)}/attendance/${paymentLinkSlug}/${sessionId}`;
};

export const landingPageLinkUrl = (link, paymeLink = '') => {
  if (paymeLink) {
    return `${baseURL(paymeLink)}/landing/${link}`;
  }
  return `${baseURL(redux_username)}/landing/${link}`;
};

export const invoiceLinkUrl = link => {
  return `${baseURL(redux_username)}/invoices/${link}`;
};

export const donateLinkUrl = (link, paymeLink = '', planType, isSelfDomain) => {
  if (paymeLink) {
    return process.env.NODE_ENV === 'development'
      ? `${baseURL(paymeLink)}/donate/${link}`
      : `${baseURL(paymeLink, planType, isSelfDomain)}/donate/${link}`;
  }
  return `${baseURL(redux_username)}/donate/${link}`;
};

export const newDonateLinkUrl = (link, paymeLink = '', planType, isSelfDomain) => {
  if (paymeLink) {
    return process.env.NODE_ENV === 'development'
      ? `${baseURL(paymeLink)}/fundraising/${link}`
      : `${baseURL(paymeLink, planType, isSelfDomain)}/fundraising/${link}`;
  }
  return `${baseURL(redux_username)}/fundraising/${link}`;
};

export const webinarPageUrl = (id, paymeLink = '', planType, isSelfDomain) => {
  if (paymeLink) {
    return process.env.NODE_ENV === 'development'
      ? `${baseURL(paymeLink)}/${paymeLink}/webinar/${id}`
      : `${baseURL(paymeLink, planType, isSelfDomain)}/webinar/${id}`;
  }

  return process.env.NODE_ENV === 'development'
    ? `${baseURL(redux_username)}/${redux_username}/webinar/${id}`
    : `${baseURL(redux_username)}/webinar/${id}`;
};

export const eventPageUrl = (id, paymeLink = '', planType, isSelfDomain) => {
  if (paymeLink) {
    return process.env.NODE_ENV === 'development'
      ? `${baseURL(paymeLink)}/${paymeLink}/event/${id}`
      : `${baseURL(paymeLink, planType, isSelfDomain)}/event/${id}`;
  }

  return process.env.NODE_ENV === 'development'
    ? `${baseURL(redux_username)}/${redux_username}/event/${id}`
    : `${baseURL(redux_username)}/event/${id}`;
};

export const coachingPageUrl = (id, paymeLink = '') => {
  if (paymeLink) {
    return process.env.NODE_ENV === 'development'
      ? `${baseURL(paymeLink)}/${paymeLink}/coaching/${id}`
      : `${baseURL(paymeLink)}/coaching/${id}`;
  }

  return process.env.NODE_ENV === 'development'
    ? `${baseURL(redux_username)}/${redux_username}/coaching/${id}`
    : `${baseURL(redux_username)}/coaching/${id}`;
};

export const fundraisingPageUrl = (id, paymeLink = '', planType, isSelfDomain) => {
  if (paymeLink) {
    return process.env.NODE_ENV === 'development'
      ? `${baseURL(paymeLink)}/${paymeLink}/galang-dana/v2/${id}`
      : `${baseURL(paymeLink, planType, isSelfDomain)}/galang-dana/v2/${id}`;
  }
  return process.env.NODE_ENV === 'development'
    ? `${baseURL(redux_username)}/${redux_username}/galang-dana/v2/${id}`
    : `${baseURL(redux_username)}/galang-dana/v2/${id}`;
};

export const qurbanPageUrl = (id, paymeLink = '', planType, isSelfDomain) => {
  if (paymeLink) {
    return process.env.NODE_ENV === 'development'
      ? `${baseURL(paymeLink)}/${paymeLink}/qurban/v2/${id}`
      : `${baseURL(paymeLink, planType, isSelfDomain)}/qurban/v2/${id}`;
  }
  return process.env.NODE_ENV === 'development'
    ? `${baseURL(redux_username)}/${redux_username}/qurban/v2/${id}`
    : `${baseURL(redux_username)}/qurban/v2/${id}`;
};

export const waqafPageUrl = (id, paymeLink = '', planType, isSelfDomain) => {
  if (paymeLink) {
    return process.env.NODE_ENV === 'development'
      ? `${baseURL(paymeLink)}/${paymeLink}/wakaf/v2/${id}`
      : `${baseURL(paymeLink, planType, isSelfDomain)}/wakaf/v2/${id}`;
  }
  return process.env.NODE_ENV === 'development'
    ? `${baseURL(redux_username)}/${redux_username}/wakaf/v2/${id}`
    : `${baseURL(redux_username)}/wakaf/v2/${id}`;
};

export const catalogPageUrl = (id, paymeLink = '', planType, isSelfDomain) => {
  if (paymeLink) {
    return process.env.NODE_ENV === 'development'
      ? `${baseURL(paymeLink)}/${paymeLink}/catalog/${id}`
      : `${baseURL(paymeLink, planType, isSelfDomain)}/catalog/${id}`;
  }
  return process.env.NODE_ENV === 'development'
    ? `${baseURL(redux_username)}/${redux_username}/catalog/${id}`
    : `${baseURL(redux_username)}/catalog/${id}`;
};

export const paymentLinkDetailUrl = (id, paymeLink = '', planType, isSelfDomain) => {
  if (paymeLink) {
    return process.env.NODE_ENV === 'development'
      ? `${baseURL(paymeLink)}/${paymeLink}/plink/${id}`
      : `${baseURL(paymeLink, planType, isSelfDomain)}/plink/${id}`;
  }
  return process.env.NODE_ENV === 'development'
    ? `${baseURL(redux_username)}/${redux_username}/plink/${id}`
    : `${baseURL(redux_username)}/plink/${id}`;
};

export const onlineCourseDetailUrl = (id, paymeLink = '', planType, isSelfDomain) => {
  if (paymeLink) {
    return process.env.NODE_ENV === 'development'
      ? `${baseURL(paymeLink)}/${paymeLink}/course/${id}`
      : `${baseURL(paymeLink, planType, isSelfDomain)}/course/${id}`;
  }
  return process.env.NODE_ENV === 'development'
    ? `${baseURL(redux_username)}/${redux_username}/course/${id}`
    : `${baseURL(redux_username)}/course/${id}`;
};

export const cohortCourseDetailUrl = (id, paymeLink = '') => {
  if (paymeLink) {
    return process.env.NODE_ENV === 'development'
      ? `${baseURL(paymeLink)}/${paymeLink}/bootcamp/${id}`
      : `${baseURL(paymeLink)}/bootcamp/${id}`;
  }
  return process.env.NODE_ENV === 'development'
    ? `${baseURL(redux_username)}/${redux_username}/bootcamp/${id}`
    : `${baseURL(redux_username)}/bootcamp/${id}`;
};

export const coachingDetailUrl = (id, paymeLink = '', planType, isSelfDomain) => {
  if (paymeLink) {
    return process.env.NODE_ENV === 'development'
      ? `${baseURL(paymeLink)}/${paymeLink}/coaching/${id}`
      : `${baseURL(paymeLink, planType, isSelfDomain)}/coaching/${id}`;
  }
  return process.env.NODE_ENV === 'development'
    ? `${baseURL(redux_username)}/${redux_username}/coaching/${id}`
    : `${baseURL(redux_username)}/coaching/${id}`;
};

export const audiobookDetailUrl = (id, paymeLink = '', planType, isSelfDomain) => {
  if (paymeLink) {
    return process.env.NODE_ENV === 'development'
      ? `${baseURL(paymeLink)}/${paymeLink}/audiobook/${id}`
      : `${baseURL(paymeLink, planType, isSelfDomain)}/audiobook/${id}`;
  }
  return process.env.NODE_ENV === 'development'
    ? `${baseURL(redux_username)}/${redux_username}/audiobook/${id}`
    : `${baseURL(redux_username)}/audiobook/${id}`;
};

export const ebookDetailUrl = (id, paymeLink = '', planType, isSelfDomain) => {
  if (paymeLink) {
    return process.env.NODE_ENV === 'development'
      ? `${baseURL(paymeLink)}/${paymeLink}/ebook/${id}`
      : `${baseURL(paymeLink, planType, isSelfDomain)}/ebook/${id}`;
  }
  return process.env.NODE_ENV === 'development'
    ? `${baseURL(redux_username)}/${redux_username}/ebook/${id}`
    : `${baseURL(redux_username)}/ebook/${id}`;
};

export const writingDetailUrl = (id, paymeLink = '', planType, isSelfDomain) => {
  if (paymeLink) {
    return process.env.NODE_ENV === 'development'
      ? `${baseURL(paymeLink)}/${paymeLink}/writing/${id}`
      : `${baseURL(paymeLink, planType, isSelfDomain)}/writing/${id}`;
  }
  return process.env.NODE_ENV === 'development'
    ? `${baseURL(redux_username)}/${redux_username}/writing/${id}`
    : `${baseURL(redux_username)}/writing/${id}`;
};

export const podcastDetailUrl = (id, paymeLink = '', planType, isSelfDomain) => {
  if (paymeLink) {
    return process.env.NODE_ENV === 'development'
      ? `${baseURL(paymeLink)}/${paymeLink}/podcast/${id}`
      : `${baseURL(paymeLink, planType, isSelfDomain)}/podcast/${id}`;
  }
  return process.env.NODE_ENV === 'development'
    ? `${baseURL(redux_username)}/${redux_username}/podcast/${id}`
    : `${baseURL(redux_username)}/podcast/${id}`;
};

export const landingPageDetailUrl = (id, paymeLink = '') => {
  if (paymeLink) {
    return process.env.NODE_ENV === 'development'
      ? `${baseURL(paymeLink)}/${paymeLink}/landing/${id}`
      : `${baseURL(paymeLink)}/landing/${id}`;
  }
  return process.env.NODE_ENV === 'development'
    ? `${baseURL(redux_username)}/${redux_username}/landing/${id}`
    : `${baseURL(redux_username)}/landing/${id}`;
};

export const simpleLandingPageDetailUrl = (id, paymeLink = '') => {
  if (paymeLink) {
    return process.env.NODE_ENV === 'development'
      ? `${baseURL(paymeLink)}/${paymeLink}/lp/${id}`
      : `${baseURL(paymeLink)}/lp/${id}`;
  }
  return process.env.NODE_ENV === 'development'
    ? `${baseURL(redux_username)}/${redux_username}/lp/${id}`
    : `${baseURL(redux_username)}/lp/${id}`;
};

export const productCatalogPageUrl = (id, paymeLink = '', planType, isSelfDomain) => {
  if (paymeLink) {
    return process.env.NODE_ENV === 'development'
      ? `${baseURL(paymeLink)}/${paymeLink}/product-catalog/${id}`
      : `${baseURL(paymeLink, planType, isSelfDomain)}/product-catalog/${id}`;
  }
  return process.env.NODE_ENV === 'development'
    ? `${baseURL(redux_username)}/${redux_username}/product-catalog/${id}`
    : `${baseURL(redux_username)}/product-catalog/${id}`;
};

export const catalogUrlv2 = link => {
  return process.env.NODE_ENV === 'development'
    ? `${baseURL(redux_username)}/${link}/profile`
    : `${baseURL(redux_username)}/profile`;
};

export const bundlingDetailUrl = (id, paymeLink = '') => {
  const username = paymeLink || redux_username;
  return process.env.NODE_ENV === 'development'
    ? `${baseURL(username)}/${username}/bundling/${id}`
    : `${baseURL(username)}/bundling/${id}`;
};

export const subscriptionUrl = (link, paymeLink = '', planType, isSelfDomain) => {
  if (paymeLink) {
    return `${baseURL(paymeLink, planType, isSelfDomain)}/s/${link}`;
  }
  return `${baseURL(redux_username)}/s/${link}`;
};

export const paySubscriptionUrl = (link, paymeLink = '') => {
  if (paymeLink) {
    return `${baseURL(paymeLink)}/subs/${link}`;
  }
  return `${baseURL(redux_username)}/subs/${link}`;
};

export const membershipUrl = (id, paymeLink = '') => {
  if (paymeLink) {
    return process.env.NODE_ENV === 'development'
      ? `${baseURL(paymeLink)}/${paymeLink}/membership/${id}`
      : `${baseURL(paymeLink)}/membership/${id}`;
  }
  return process.env.NODE_ENV === 'development'
    ? `${baseURL(redux_username)}/${redux_username}/membership/${id}`
    : `${baseURL(redux_username)}/membership/${id}`;
};

export const membershipCheckoutUrl = (link, paymeLink = '', planType, isSelfDomain) => {
  if (paymeLink) {
    return `${baseURL(paymeLink, planType, isSelfDomain)}/m/${link}`;
  }
  return `${baseURL(redux_username)}/m/${link}`;
};

export const payMembersipStaticUrl = paymeLink => {
  return process.env.NODE_ENV === 'development'
    ? `${baseURL(redux_username)}/${paymeLink}/pay-membership`
    : `${baseURL(redux_username)}/pay-membership`;
};

export const membershipLinkStaticUrl = (paymeLink, memberId) => {
  return process.env.NODE_ENV === 'development'
    ? `${baseURL(redux_username)}/${paymeLink}/pay-membership/${memberId}`
    : `${baseURL(redux_username)}/pay-membership/${memberId}`;
};

export const isSignPage = check => {
  if (!check) {
    check = window.location.hash ? window.location.hash.replace(/^#/g, '') : window.location.pathname;
  }
  return check === '/sign-in' || check.includes('/sign-in/');
};

export const isPublicPage = check => {
  if (!check) {
    check = window.location.hash ? window.location.hash.replace(/^#/g, '') : window.location.pathname;
  }

  return (
    check.match(/pl\/.*/) ||
    check.match(/checkout\/.*/) ||
    check.match(/select-shipping\/.*/) ||
    check.match(/payme\/.*/) ||
    check.match(/donate\/.*/) ||
    check.match(/plt\/.*/) ||
    check.match(/s\/.*/) ||
    check.match(/pay-thank-you\/.*/) ||
    check === '/pay-channel-qris' ||
    check === '/pay-me-select-channel' ||
    check === '/pay-me-channel-qris' ||
    check === '/pay-channel-va' ||
    check === '/pay-failed' ||
    check === '/pay-channel-retail' ||
    check === '/pay-credit-card' ||
    check === '/pay-channel-ovo' ||
    //affiliate
    check.match(/affiliate-register\/.*/) ||
    //customer portal
    check.match(/.*\/portal\/.*/) ||
    check.match(/portal\/dashboard\/.*/) ||
    check.match(/portal\/recurring-detail\/.*/) ||
    check.match(/portal\/invoice-detail\/.*/) ||
    check.match(/portal\/download\/.*/) ||
    //invoice
    check.match(/invoice\/.*/) ||
    //digital product
    check === '/pay-digital-product' ||
    check.match(/.*\/catalog/) ||
    check.match(/.*\/catalog\/.*/) ||
    //fundraising
    check.match(/.*\/galang-dana/) ||
    check.match(/.*\/galang-dana\/embed/) ||
    check.match(/.*\/galang-dana\/.*/) ||
    //webinar
    check.match(/.*\/webinar\/.*/)
  );
};

export const isExceptionPage = check => {
  if (!check) {
    check = window.location.hash ? window.location.hash.replace(/^#/g, '') : window.location.pathname;
  }

  return (
    // web editor
    check.match(/.*\/landing-page-editor/) ||
    check.match(/.*\/landing-page-editor\/.*/) ||
    check.match(/.*\/simple-landing-page-editor/) ||
    check.match(/.*\/simple-landing-page-editor\/.*/) ||
    // dev api playground
    check.match(/.*\/playground/) ||
    check.match(/.*\/playground\/.*/) ||
    // email editor
    check.match(/.*\/broadcast-email-editor/) ||
    check.match(/.*\/broadcast-email-editor\/.*/)
  );
};

export const isAdminPage = check => {
  if (!check) {
    check = window.location.hash ? window.location.hash.replace(/^#/g, '') : window.location.pathname;
  }

  return check.substring(0, 6) === '/admin';
};

export const getLinkTreePathMayarURL = (url = '') => {
  let path = url;
  const reHostURL = /(((https)||(http))?:\/\/(?:[a-zA-Z0-9_]+[^\/]+)\/)/i;

  if (reHostURL.test(url)) {
    path = `/${url?.split('https://')[1]?.split('/')?.splice(1)?.join('/')}`;
  }

  return path;
};

export const getPaymentLinkDetailPath = (paymentLinkType = 'generic_link', paymentLinkSubType) => {
  let path = '/payment-link-detail/';

  if (paymentLinkType === 'generic_link') {
    path = '/payment-link-detail/';
  } else if (paymentLinkType === 'physical_product') {
    path = '/physical-product-detail/';
  } else if (paymentLinkType === 'digital_product') {
    path = '/digital-product-detail/';
  } else if (paymentLinkType === 'course') {
    path = '/online-course-detail/';
  } else if (paymentLinkType === 'webinar') {
    path = '/webinar-detail/';
  } else if (paymentLinkType === 'event') {
    path = '/event-detail/';
  } else if (paymentLinkType === 'coaching') {
    path = '/coaching-detail/';
  } else if (paymentLinkType === 'ebook') {
    path = '/ebook-detail/';
  } else if (paymentLinkType === 'podcast') {
    path = '/podcast-detail/';
  } else if (paymentLinkType === 'audiobook') {
    path = '/audiobook-detail/';
  } else if (paymentLinkType === 'cohort_based') {
    path = '/cohort-course-detail/';
  } else if (paymentLinkType === 'saas') {
    path = '/license-code-detail/';
  } else if (paymentLinkType === 'membership') {
    path = '/membership-detail/';
  } else if (paymentLinkSubType === 'FUNDRAISING_DONATION') {
    path = '/fundraising-detail/';
  } else if (paymentLinkSubType === 'FUNDRAISING_WAQAF') {
    path = '/waqaf-detail/';
  } else if (paymentLinkSubType === 'FUNDRAISING_QURBAN') {
    path = '/qurban-detail/';
  }

  return path;
};

const productPath = {
  saas: 'app'
};

// TODO: Change other products and catalog url to use this function
export const urlProductDetail = (type, id, paymeLink = '', planType, isSelfDomain) => {
  if (paymeLink) {
    return process.env.NODE_ENV === 'development'
      ? `${baseURL(paymeLink)}/${paymeLink}/${productPath[type]}/${id}`
      : `${baseURL(paymeLink, planType, isSelfDomain)}/${productPath[type]}/${id}`;
  }
  return process.env.NODE_ENV === 'development'
    ? `${baseURL(redux_username)}/${redux_username}/${productPath[type]}/${id}`
    : `${baseURL(redux_username)}/${productPath[type]}/${id}`;
};

export const urlCatalog = (type, link) => {
  return process.env.NODE_ENV === 'development'
    ? `${baseURL(redux_username)}/${link}/${productPath[type]}`
    : `${baseURL(redux_username)}/${productPath[type]}`;
};

export const createWhatsappLink = (mobile, message) => encodeURI(`https://wa.me/${mobile}?text=${message}`);

export const createQueryStringFromObject = paramsObject => {
  const queryParams = new URLSearchParams();

  for (const key in paramsObject) {
    if (paramsObject.hasOwnProperty(key)) {
      queryParams.append(key, paramsObject[key]);
    }
  }

  return queryParams.toString();
};
