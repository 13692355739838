import { gql } from '@apollo/client';

export const GQL_INSERT_COHORT_SESSION = gql`
  mutation insertCohortSession($input: CohortSessionInput!) {
    insertCohortSession(input: $input) {
      description
      eventEndAt
      eventStartAt
      eventUrl
      id
      name
      speaker
      speakerProfile
      cohortAttendances {
        id
        email
        createdAt
        feedback
        isAttend
      }
    }
  }
`;

export const GQL_EDIT_COHORT_SESSION = gql`
  mutation editCohortSession($input: CohortSessionInput!) {
    editCohortSession(input: $input) {
      description
      eventEndAt
      eventStartAt
      eventUrl
      id
      name
      speaker
      speakerProfile
      cohortAttendances {
        id
        email
        createdAt
        feedback
        isAttend
      }
    }
  }
`;

export const GQL_GET_COHORT_SESSION = gql`
  query getPaymentLink($id: ID) {
    getPaymentLink(id: $id) {
      id
      amount
      category
      createdAt
      startAt
      expiredAt
      eventStartAt
      eventEndAt
      limit
      paymentType
      embedUrl
      isBootcamp
      terms
      reminderWellcomeId
      urlParamTracking
      multipleImage {
        url
        updatedAt
      }
      certificate {
        id
        layout
        name
        templateImageId
        startAt
        templateImage {
          id
          url
          title
        }
      }
      form {
        fieldObject
        id
        name
      }
      description
      notes
      type
      status
      redirectUrl
      name
      link
      user {
        id
        bunnyLibraryId
        account {
          name
        }
      }
      courseSections {
        description
        id
        name
        position
        link
        status
        courseModules {
          id
          name
          type
          startAt
          endAt
          position
          status
          link
          coachingUrl
          description
          embedUrl
          isPreview
          isContentNotProtected
          isVisible
          quiz
          content {
            files {
              id
              filename
              size
              mimetype
              createdAt
            }
            file {
              id
              filename
              size
              mimetype
              createdAt
              bunnyVideoId
            }
            id
            createdAt
          }
        }
      }
      checkoutSettings {
        id
        buttonName
        buttonNote
        discountButton
        couponOption
        paymentLinkId
        termOption
        termUrl
      }
    }
  }
`;

export const GQL_INSERT_COHORT_ATTENDANCE = gql`
  mutation insertCohortAttendance($input: CohortAttendanceInput!) {
    insertCohortAttendance(input: $input) {
      cohortSessionId
      createdAt
      email
      id
      isAttend
      name
      paymentLinkId
      userId
    }
  }
`;

export const GQL_GET_COHORT_ATTENDANCE = gql`
  query getCohortAttendancePage(
    $page: Int
    $pageSize: Int
    $sortDirection: SortDirection
    $sortField: CohortAttendanceSortField
    $searchAny: CohortAttendanceSearchInput
    $search: CohortAttendanceSearchInput
  ) {
    getCohortAttendancePage(
      page: $page
      pageSize: $pageSize
      sortDirection: $sortDirection
      sortField: $sortField
      searchAny: $searchAny
      search: $search
    ) {
      items {
        cohortSessionId
        createdAt
        email
        feedback
        isAttend
        name
        paymentLinkId
        userId
      }
      total
      page
      offset
      totalPage
      prevPage
      nextPage
    }
  }
`;

export const GQL_GET_COHORT_PAGE = gql`
  query getCohortSessionPage(
    $page: Int
    $pageSize: Int
    $sortDirection: SortDirection
    $sortField: CohortSessionSortField
    $searchAny: CohortSessionSearchInput
    $search: CohortSessionSearchInput
  ) {
    getCohortSessionPage(
      page: $page
      pageSize: $pageSize
      sortDirection: $sortDirection
      sortField: $sortField
      searchAny: $searchAny
      search: $search
    ) {
      total
      page
      offset
      totalPage
      prevPage
      nextPage
      items {
        description
        eventEndAt
        eventStartAt
        eventUrl
        id
        name
        speaker
        speakerProfile
        cohortAttendances {
          id
          email
          createdAt
          feedback
          isAttend
        }
      }
    }
  }
`;

export const GQL_GET_COHORT_SESSION_FILTERED_LIST = gql`
  query getCohortSessionFilteredList(
    $limit: Int
    $sortDirection: SortDirection
    $sortField: CohortSessionSortField
    $searchAny: CohortSessionSearchInput
    $search: CohortSessionSearchInput
  ) {
    getCohortSessionFilteredList(
      limit: $limit
      sortDirection: $sortDirection
      sortField: $sortField
      searchAny: $searchAny
      search: $search
    ) {
      description
      eventEndAt
      eventStartAt
      eventUrl
      id
      name
      speaker
      speakerProfile
      isOnlineEvent
      mapLocation
      cohortAttendances {
        id
        email
        createdAt
        feedback
        isAttend
      }
    }
  }
`;
