import React from 'react';
import ContentLoader from 'react-content-loader';

const LoadingAnimation = (props) => {
  if (props.mayar) {
    return (
      <ContentLoader
        speed={1}
        width={340}
        height={84}
        viewBox="0 0 340 84"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <path d="M 93.13 98.8 l 4.65 -30.6 c 1.41 -9.18 3.32 -20.67 6.03 -34.76 h -0.39 c -4.98 12.64 -10.29 24.95 -14.39 33.65 l -13.6 29.87 H 58.87 l -1.41 -29.8 c -0.41 -8.3 -1 -20.81 -1.16 -33.72 h -0.26 c -2.71 13.12 -5.22 25.65 -7.35 34.76 l -7.13 30.6 H 22.2 l 22.22 -88.72 H 71.8 l 0.99 29.88 c 0.27 7.97 1.02 18.72 0.89 28.43 h 0.47 c 3.18 -9.72 7.83 -20.82 11.23 -28.57 l 12.6 -29.74 h 27.53 L 113.44 98.8 H 93.13 z" />
        <path d="M 71.79 9.95 H 7.71 S 61.5 25.58 73.04 45.67 L 71.79 9.95 z" />
        <rect x="137" y="11" rx="0" ry="0" width="200" height="10" />
        <rect x="137" y="31" rx="0" ry="0" width="152" height="10" />
        <rect x="137" y="53" rx="0" ry="0" width="123" height="10" />
        <rect x="137" y="71" rx="0" ry="0" width="152" height="10" />
      </ContentLoader>
    );
  }
  
  if (props.table) {
    return (
      <ContentLoader viewBox="0 0 400 75">
        <rect x="0" y="0" rx="0" ry="0" width="400" height="1" />
        <rect x="0" y="19" rx="0" ry="0" width="400" height="1" />
        <rect x="0" y="24" rx="0" ry="0" width="400" height="3" />
        <rect x="0" y="31" rx="0" ry="0" width="23" height="3" />
        <rect x="0" y="43" rx="0" ry="0" width="199" height="1" />
        <rect x="200" y="43" rx="0" ry="0" width="200" height="1" />
        <rect x="0" y="54" rx="0" ry="0" width="199" height="1" />
        <rect x="200" y="54" rx="0" ry="0" width="200" height="1" />
        <rect x="0" y="67" rx="0" ry="0" width="199" height="1" />
        <rect x="200" y="67" rx="0" ry="0" width="200" height="1" />
        <rect x="0" y="80" rx="0" ry="0" width="199" height="1" />
        <rect x="200" y="80" rx="0" ry="0" width="200" height="1" />
        <rect x="32" y="5" rx="0" ry="0" width="10" height="10" />
        <rect x="86" y="5" rx="0" ry="0" width="10" height="10" />
        <rect x="170" y="5" rx="0" ry="0" width="10" height="10" />
        <rect x="239" y="5" rx="0" ry="0" width="10" height="10" />
        <rect x="341" y="5" rx="0" ry="0" width="41" height="10" />
      </ContentLoader>
    );
  }

  if (props.twodashline) {
    return (
      <ContentLoader viewBox="0 0 100 31" height={31} width="100%" preserveAspectRatio="none">
        <rect height="5.5" rx="1" ry="1" width="100%" x="0" y="5" />
        <rect height="5.5" rx="1" ry="1" width="100%" x="0" y="15" />
      </ContentLoader>
    );
  }

  if (props.qrcode) {
    return (
      <ContentLoader width={"100%"} viewBox="0 0 265 230">
        <rect x="15" y="0" rx="2" ry="2" width="350" height="250" />
        <rect x="15" y="230" rx="2" ry="2" width="170" height="20" />
        <rect x="60" y="230" rx="2" ry="2" width="170" height="20" />
      </ContentLoader>
    );
  }
  
  return (
    <ContentLoader
      speed={1}
      viewBox="0 0 340 84"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="9" y="4" rx="0" ry="0" width="320" height="22" />
      <rect x="18" y="14" rx="0" ry="0" width="303" height="6" />
      <rect x="11" y="33" rx="0" ry="0" width="108" height="13" />
      <rect x="129" y="33" rx="0" ry="0" width="60" height="13" />
      <rect x="196" y="33" rx="0" ry="0" width="60" height="13" />
    </ContentLoader>
  );
};

export default LoadingAnimation;
